import * as React from "react"
import Helmet from "react-helmet"

import profile from "../data/profile.json"
import projects from "../data/projects.json"
import brands from "../data/brands.json"


/**
 * Types
 */
interface IData {
    profile: IProfile;
    projects: [IProjects];
    brands: [string];
}

interface IProfile {
    fullName: string;
    email: string;
    telephone: string;
    headline: string;
    location: string;
    summary: [string];
    networks: INetworks;
}

interface IImage {
    path: string;
    caption: string;
}

interface INetworks {
    [key: string]: {
        url: string,
        title: string
    };
}

interface INetwork {
    url: string;
    title: string;
}

interface IHighlight {
    title: string;
    copy: string;
}

interface IProjects {
    featured: boolean;
    brand: string;
    title: string;
    roleTitle: string;
    roleType: string;
    duration: string;
    description: string;
    highlights: [IHighlight];
    images: [IImage];
}


/**
 * Init Vars
 */
const data: IData = {...profile, ...projects, ...brands};

const Project = (props: IProjects) => {
    const {featured = false, brand, roleTitle, roleType, duration, description, highlights} = props;

    return <div className={'project'}>
        <h3><strong>{brand}</strong> – {roleTitle} {featured ? ` –  ${roleType} – ${duration}` : null}</h3>
        <p>
            {description}
        </p>
        {featured ? <ul className={"highlights"}>
            {highlights.map(({title, copy}, index) => <li key={index}><strong>{title}</strong> – {copy}</li>)}
        </ul> : null}
    </div>
}


/**
 * Config
 */

const VR = {
    "settings": {
        "content": {
            "summary": true,
            "selectedExperience": false,
            "additonalExperience": false,
            "brandExposure": true,
            "references": true,
        }
    }
}

/**
 * Return Markup
 */
const IndexPage = () => {
    const {profile, projects, brands} = data;
    const {content: contentSettings} = VR.settings;
    const {linkedin, yunojuno} = profile.networks;

    // React.useEffect(() => {
    //     document.documentElement.classList.add('light')
    // })

    return (
        <main>
            <Helmet defer={false} htmlAttributes={{ lang: 'en' }}>
                <title>
                    Vincent Roman - Fullstack Developer
                </title>
                <meta name="description"
                      content="Vincent Roman - A fullstack developer with over two decades of experience as a self-motivated, self-taught, problem-solving skills. "/>
                <link rel="canonical" href="https://www.vincentroman.com/"/>
            </Helmet>
            <header>
                <h1>{profile.fullName}</h1>
                <sub>
                    Fullstack Developer /
                    {['linkedin', 'yunojuno'].map((n, i) => {
                        const www = profile.networks[n];
                        return (<>{i > 0 ? ` / ` : ` `} <a href={www.url} target="_BLANK">{www.title}</a> </>)
                    })}
                </sub>
            </header>
            {contentSettings.summary ? <section>
                <h2>TL;DR</h2>
                {profile.summary.map(copy => <p>{copy}</p>)}
            </section> : null}
            {contentSettings.selectedExperience ? <section>
                <h2>Recent / Selected Experience</h2>
                <ul className={"projects"}>
                    {projects.filter(p => p.featured).map(project => <li><Project {...project} /></li>)}
                </ul>
            </section> : null}
            {contentSettings.additonalExperience ? <section>
                <h2>Additional Experience</h2>
                <ul className={"projects"}>
                    {projects.filter(({featured = false}) => !featured).map(project => <li><Project {...project} />
                    </li>)}
                </ul>
            </section> : null}
            {contentSettings.brandExposure ? <section>
                <h2>Brand Exposure</h2>
                <p>{brands.map((brand, i) => (<span key={`brand-${i}`}>{brand}, </span>))} and more over 10+ years;</p>
            </section> : null}
            {contentSettings.references ? <section>
                <h2>References & Code</h2>
                {["References and code are available upon request."].map((copy, i) => <p key={`copy-${i}`}>{copy}</p>)}
            </section> : null}
        </main>
    )
}

export default IndexPage
